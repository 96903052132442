import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";

import { AuthContext, Authenticated } from './auth'
import Protected from './Protected'

import RegisterPage from './RegisterPage'
import PlusRegisterPage from './PlusRegisterPage'



const basename = window.location.href ? '/react-pkce-sample' : undefined
//const basename = window.location.href 


function App() {

  const handleClickWithLoginTypeMobile = () => {
    const json =  {
      loginType:"mobile",
      countryCode:"+852",
      nationalNumber:"65368584",
      username:"85265368584"
    };
    sessionStorage.setItem('policy', 'B2C_1A_PLUS_LOGIN');
    sessionStorage.setItem('ui_locales', 'en');
    sessionStorage.setItem('loginhints', JSON.stringify(json));
  };

  const handleClickWithLoginTypeUsername = () => {
    const json =  {
      loginType:"username",
      username:"85265368584"
    };
    sessionStorage.setItem('policy', 'B2C_1A_PLUS_LOGIN');
    sessionStorage.setItem('ui_locales', 'en');
    sessionStorage.setItem('loginhints', JSON.stringify(json));
  };



  const handleClickWithLoginChinese = () => {
    const json =  {
      loginType:"username",
      username:"85265368584"
    };
    sessionStorage.setItem('policy', 'B2C_1A_PLUS_LOGIN');
    sessionStorage.setItem('ui_locales', 'zh-hant');
    sessionStorage.setItem('loginhints', JSON.stringify(json));
  };


  const handleClickWithLoginChineseS = () => {
    const json =  {
      loginType:"username",
      username:"85265368584"
    };
    sessionStorage.setItem('policy', 'B2C_1A_PLUS_LOGIN');
    sessionStorage.setItem('ui_locales', 'zh-hans');
    sessionStorage.setItem('loginhints', JSON.stringify(json));
  };



  const handleClickRegister = () => {
    sessionStorage.setItem('policy', 'B2C_1A_PLUS_SIGNUP');
    sessionStorage.removeItem('loginhints');
  };

  
  const handleClickRegisterWILL = () => {
    sessionStorage.setItem('policy', 'B2C_1A_PLUS_SIGNUP_WILL');
    sessionStorage.removeItem('loginhints');
  };

  
  const handleClickRegisterForgotPassword = () => {
    sessionStorage.setItem('policy', 'B2C_1A_PLUS_FORGOTPASSWORD');
    sessionStorage.removeItem('loginhints');
  };




  return (
    <div style={{ padding: 20, background:'#ae9292' }}>
      <h1>TDA Burger Register - v2.0.20230929_04_28</h1>
      <AuthContext>
        <div>
        <a id="home" className="menu-item" href="/">Home</a> &nbsp;&nbsp;
        <a id="home" className="menu-item" href="/register">CONNECT Register</a> &nbsp;&nbsp;
        <a id="home" className="menu-item" href="/plus-register">Plus Register</a> &nbsp;&nbsp;
        <a onClick={handleClickRegister} id="home" className="menu-item" href="/protected">Plus Sign Up </a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a onClick={handleClickWithLoginTypeMobile} id="home" className="menu-item" href="/protected">Login with Login Type Mobile</a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a onClick={handleClickWithLoginTypeUsername} id="home" className="menu-item" href="/protected">Login with Login Type Username</a>&nbsp;&nbsp;&nbsp;&nbsp;


          <a onClick={handleClickWithLoginChinese} id="home" className="menu-item" href="/protected">使用繁体中文登入 </a>&nbsp;&nbsp;&nbsp;&nbsp;
          <a onClick={handleClickWithLoginChineseS} id="home" className="menu-item" href="/protected">使用简体中文登录 </a>&nbsp;&nbsp;&nbsp;&nbsp;


          <a onClick={handleClickRegisterWILL} id="home" className="menu-item" href="/protected">WILL TESTING  </a>&nbsp;&nbsp;&nbsp;&nbsp;




          <a onClick={handleClickRegisterForgotPassword} id="home" className="menu-item" href="/protected">Forgot Password  </a>&nbsp;&nbsp;&nbsp;&nbsp;


        </div>
        <BrowserRouter>
          <Routes>



          <Route path="/" element={<div >This is ADB2C Demo</div>} />
            <Route path="/protected" element={<Authenticated>
              <Protected />
            </Authenticated>} />

            <Route path="/register" element={<RegisterPage />}/>
            <Route path="/plus-register" element={<PlusRegisterPage />}/>


            



          </Routes>
        </BrowserRouter>
      </AuthContext>
    </div>
  );
}

export default App;
