export const fetchToken = ({ clientId, clientSecret, code, verifier, tokenEndpoint, fetch = window.fetch }) => {
  const payload = {
    client_secret: clientSecret,
    client_id: clientId,
    code,
    grant_type: 'authorization_code',
    code_verifier: verifier,
    redirect_uri: 'https://spa.tdaburger.com/protected',
  };


  let updatedtokenEndpoint = "https://tdaburger.b2clogin.com/tdaburger.onmicrosoft.com/B2C_1A_PLUS_SIGNUP/oauth2/v2.0/token"
  if(sessionStorage.getItem('policy')){
    updatedtokenEndpoint =    updatedtokenEndpoint.replaceAll("B2C_1A_PLUS_SIGNUP", sessionStorage.getItem('policy'));
  }


  return fetch(updatedtokenEndpoint, {
    headers: {
      // 'Content-Type': 'application/json'
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    method: 'POST',
    //body: payload
    body: new URLSearchParams(payload)
  })
    .then(r => {
      if (!r.ok) {
        throw new Error(`Token response not ok, status is ${r.status}, check the  configuration (wrong provider or token endpoint?)`);
      }
      return r.json();
    })
    .then(token => {

      console.log("token  !!!!!!");
      console.log(token);


      const { expires_in } = token;
      if (expires_in && Number.isFinite(expires_in)) {
        const slackSeconds = 10;
        // add 'expires_at', with the given slack
        token.expires_at = new Date(new Date().getTime() + expires_in * 1000 - (slackSeconds * 1000));
      }
      return token;
    })
    .catch(err => {
      console.error('ERR (fetch)', err);
      throw err;
    });
}
