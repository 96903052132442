import { base64URLEncode, sha256 } from "./sha256-base64-url-encode"
import createCodeVerifier from './create-code-verifier'
import hashed from './hashed'
import getEncodedVerifierKey from './getEncodedVerifierKey'

export default function authorize({provider, clientId, storage = sessionStorage}) {

  const encodedVerifier = base64URLEncode(createCodeVerifier())
  storage.setItem(
    getEncodedVerifierKey(clientId),
    encodedVerifier
  )


 const challengeCode = base64URLEncode(sha256(encodedVerifier));

  console.log("challengeCode");
  console.log(challengeCode);

  let query = {
    client_id: clientId,
    response_type: 'code',
    redirect_uri: 'https://spa.tdaburger.com/protected',//window.location,
    nonce:'defaultNonce',
    scope: 'openid offline_access',
    prompt:'login',
    code_challenge: challengeCode,
    code_challenge_method: 'S256',
    isProspect: 'true',
  }

  if(sessionStorage.getItem('loginhints')){
      const strJson  = sessionStorage.getItem('loginhints');
      const resultSet = JSON.parse(strJson);
        if(resultSet.loginType === "mobile"){
          query.loginType = resultSet.loginType;
          query.countryCode = resultSet.countryCode;
          query.nationalNumber = resultSet.nationalNumber;
          query.username = resultSet.username;
          }
          else if(resultSet.loginType === "username"){
              query.loginType = resultSet.loginType;
              query.username = resultSet.username;
          }
     
    //query.login_hint = sessionStorage.getItem('loginhints');
  }


  if(sessionStorage.getItem('ui_locales')){
    query.ui_locales = sessionStorage.getItem('ui_locales');
  }
    


  let updatePrvider = provider;
  if(sessionStorage.getItem('policy')){
    updatePrvider =    provider.replaceAll("B2C_1A_PLUS_SIGNUP", sessionStorage.getItem('policy'));
  }





  const url = `${ updatePrvider }/authorize?${ hashed(query) }`


  console.log("experience URL>>>>>>>");

   console.log(url);

  window.location.replace(url)
}
